import Vue from "vue";
const { __getText } = Vue.prototype;
import { FormBlock } from "@/components/FormBuilder/types";
import {
  useFormSection,
  useFormFieldGroup,
  useTextField,
  useSelectField,
  useField
} from "@/components/FormBuilder/Helpers";
import { readOnlyAgencyFormFields } from "../agencyAdmin/readOnlyAgencyFormFields";
import * as selectOptions from "@/helpers/selectOptions";
import { validateSchema } from "@/helpers/validateSchema";
import { addressForm, mailingAddressForm } from "../shared/address";

interface IAgencyFormProps {
  userIsAdmin: boolean;
  agencyHasAdmin: boolean;
  agencyIsDeleted?: boolean;
  hasSelectedInsurorsIndemnity: boolean;
  hasSelectedInsurorsIndemnitySelect: boolean;
}
export const agencyForm = (data: IAgencyFormProps): FormBlock[] => {
  const { userIsAdmin, agencyIsDeleted } = data;
  return [
    ...createEditableOrReadOnlyFormFields(data),
    ...(userIsAdmin ? [] : agencyAdminPhoneEdit(agencyIsDeleted)),
    useField({
      key: "physicalAddress",
      label: ["fields", "physicalAddressLabel"],
      required: true,
      type: "address",
      disabled: agencyIsDeleted,
      validations: [
        {
          method: "custom",
          error: "Physical Address is Invalid",
          customValidator: (value: any) => {
            return validateSchema(
              addressForm({
                disableState: false,
                addressType: "physicalAddress"
              }),
              value
            ).isValid;
          }
        }
      ],
      addressType: "physicalAddress",
      isPOBOXMailingAddress: "false"
    }),
    useField({
      key: "mailingAddress",
      label: __getText("fields", "mailingAddressLabel"),
      required: true,
      type: "address",
      addressType: "mailingAddress",
      isPOBOXMailingAddress: "true",
      disabled: agencyIsDeleted,
      validations: [
        {
          method: "custom",
          error: "Mailing Address is invalid",
          customValidator: (value: any) => {
            return validateSchema(mailingAddressForm, value).isValid;
          }
        }
      ],
      allowSyncingWithPhysicalAddress: true,
      limitToTexas: false
    })
  ];
};

function createEditableOrReadOnlyFormFields(data: IAgencyFormProps) {
  const {
    userIsAdmin,
    agencyHasAdmin,
    agencyIsDeleted = false,
    hasSelectedInsurorsIndemnity,
    hasSelectedInsurorsIndemnitySelect
  } = data;
  return [
    //Read-only agency & underwriter details
    ...(userIsAdmin ? [] : readOnlyAgencyFormFields()),
    //Editable agency details
    ...(userIsAdmin
      ? useFormSection(
          [
            ...useFormFieldGroup(
              [
                useTextField({
                  key: "agencyName",
                  label: "Agency Name",
                  placeholder: "Agency Name",
                  required: true,
                  disabled: agencyIsDeleted
                }),
                useSelectField({
                  key: "status",
                  label: "Select Status",
                  options: selectOptions.agencyStatus,
                  required: true,
                  clearable: true,
                  disabled: agencyIsDeleted
                })
              ],
              { layout: "2-col" }
            ),
            ...useFormFieldGroup(
              [
                useField({
                  key: "phone.primary",
                  label: __getText("fields", "primaryPhoneNumberLabel"),
                  placeholder: __getText("fields", "primaryPhoneNumberLabel"),
                  required: true,
                  type: "phone",
                  disabled: agencyIsDeleted
                }),
                useField({
                  key: "phone.secondary",
                  label: __getText("fields", "secondaryPhoneNumberLabel"),
                  placeholder: __getText("fields", "secondaryPhoneNumberLabel"),
                  required: false,
                  type: "phone",
                  disabled: agencyIsDeleted
                })
              ],
              { layout: "2-col" }
            ),
            ...useFormFieldGroup(
              [
                useField({
                  key: "companyIds",
                  label: "Select Companies",
                  type: "select",
                  required: true,
                  multiple: true,
                  disabled: agencyIsDeleted
                })
              ],
              { layout: "full" }
            ),
            ...useFormFieldGroup(
              [
                ...(hasSelectedInsurorsIndemnity
                  ? [
                      useField({
                        key: "allowInsurorsSeacoast",
                        label:
                          "Allow Rating in Insurors Indemnity Seacoast (26)",
                        type: "checkbox",
                        required: false,
                        disabled: agencyIsDeleted
                      })
                    ]
                  : []),
                ...(hasSelectedInsurorsIndemnitySelect
                  ? [
                      useField({
                        key: "allowInsurorsSelectSeacoast",
                        label: "Allow Rating in Insurors Select Seacoast(32)",
                        type: "checkbox",
                        required: false,
                        disabled: agencyIsDeleted
                      })
                    ]
                  : []),
                useField({
                  key: "allowEzlynxRating",
                  label: "Allow Ezlynx Rating",
                  type: "checkbox",
                  required: false,
                  disabled: agencyIsDeleted
                })
              ],
              { layout: "3-col" }
            ),
            ...useFormFieldGroup(
              [
                useField({
                  key: "subAgencyOf",
                  label: "Sub Agency Of",
                  type: "select-search-agencies",
                  required: false,
                  clearable: true,
                  placeholder: "Enter more than 4 characters",
                  disabled: agencyIsDeleted,
                  conditions: {
                    and: [
                      {
                        operator: "not",
                        field: "role",
                        value: "underwriting"
                      }
                    ]
                  }
                }),
                useField({
                  key: "parentAgencyCode",
                  label: "Parent Agency Code",
                  type: "custom-disabled-text-field-with-input",
                  placeholder: "",
                  required: false,
                  disabled: agencyIsDeleted,
                  validations: [
                    {
                      method: "minLength",
                      value: 3,
                      error: "Agency Code Must Not Be Less Than 3 characters"
                    }
                  ],
                  conditions: {
                    and: [
                      {
                        operator: "exists",
                        field: "subAgencyOf",
                        value: true
                      }
                    ]
                  }
                }),
                useField({
                  key: "agencyCode",
                  label: "Agency Code",
                  type: "text",
                  placeholder: "Agency Code",
                  required: true,
                  disabled: agencyIsDeleted,
                  validations: [
                    {
                      method: "minLength",
                      value: 3,
                      error: "Agency Code Must Not Be Less Than 3 characters"
                    },
                    {
                      method: "maxLength",
                      value: 15,
                      error: "Agency Code Must Not Be More Than 15 characters"
                    }
                  ],
                  conditions: {
                    and: [
                      {
                        operator: "hasValue",
                        field: "companyIds",
                        value: true
                      }
                    ]
                  }
                })
              ],
              { layout: "3-col" }
            )
          ],
          {
            title: "Agency Information",
            description: "Provide some basic information about the agency"
          }
        )
      : []),
    //Agency Admin Details
    ...agencyOrSubAdminDetails("agencyAdminData", agencyHasAdmin),
    ...agencyOrSubAdminDetails("subAdminData", agencyHasAdmin),
    //Editable underwriter details
    ...(userIsAdmin
      ? useFormSection(
          [
            ...useFormFieldGroup(
              [
                useField({
                  key: "underWriterId",
                  label: "Search Underwriter",
                  type: "select-search-underwriter",
                  required: false,
                  disabled: false
                })
              ],
              { layout: "full" }
            ),
            ...useFormFieldGroup(
              [
                useField({
                  key: "underWriterData.email",
                  type: "dataview",
                  label: "Email",
                  disabled: agencyIsDeleted,
                  conditions: {
                    and: [
                      {
                        operator: "exists",
                        field: "underWriterData.email",
                        value: true
                      }
                    ]
                  }
                }),
                useField({
                  key: "underWriterData.phone.primary",
                  label: "Primary Phone number",
                  type: "dataview",
                  conditions: {
                    and: [
                      {
                        operator: "exists",
                        field: "underWriterData.phone.primary",
                        value: true
                      }
                    ]
                  }
                }),
                useField({
                  key: "underWriterData.phone.secondary",
                  label: "Secondary Phone number",
                  type: "dataview",
                  conditions: {
                    and: [
                      {
                        operator: "exists",
                        field: "underWriterData.phone.secondary",
                        value: true
                      }
                    ]
                  }
                })
              ],
              { layout: "2-col" }
            )
          ],

          {
            title: "Underwriter Information",
            description: "Information about the underwriter assigned the agency"
          }
        )
      : [])
  ];
}

export const agencyAdminPhoneEdit = (agencyIsDeleted?: boolean) => [
  ...useFormSection(
    [
      ...useFormFieldGroup(
        [
          useField({
            key: "phone.primary",
            label: __getText("fields", "primaryPhoneNumberLabel"),
            placeholder: __getText("fields", "primaryPhoneNumberLabel"),
            required: true,
            type: "phone",
            disabled: agencyIsDeleted
          })
        ],
        { layout: "2-col" }
      ),
      ...useFormFieldGroup(
        [
          useField({
            key: "allowEzlynxRating",
            label: "Allow Ezlynx Rating",
            type: "checkbox",
            required: false,
            disabled: agencyIsDeleted
          })
        ],
        { layout: "full" }
      )
    ],
    {
      title: " "
    }
  )
];

function agencyOrSubAdminDetails(
  key: "subAdminData" | "agencyAdminData",
  agencyHasAdmin: boolean
) {
  const admin = key === "agencyAdminData" ? "Agency" : "Sub";
  const title = `${admin} Administrator Information`;

  return agencyHasAdmin
    ? useFormSection(
        [
          ...useFormFieldGroup(
            [
              useField({
                key: `${key}.fullName`,
                label: "Full Name",
                type: "dataview",
                conditions: {
                  and: [
                    {
                      operator: "exists",
                      field: `${key}.fullName`,
                      value: true
                    }
                  ]
                }
              }),
              useField({
                key: `${key}.email`,
                type: "dataview",
                label: "Email",
                conditions: {
                  and: [
                    {
                      operator: "exists",
                      field: `${key}.email`,
                      value: true
                    }
                  ]
                }
              }),
              useField({
                key: `${key}.phone.primary`,
                label: "Primary Phone number",
                type: "dataview",
                conditions: {
                  and: [
                    {
                      operator: "exists",
                      field: `${key}.phone.primary`,
                      value: true
                    }
                  ]
                }
              })
            ],
            { layout: "2-col" }
          )
        ],

        {
          title,
          description: "Information about the administrator of the agency"
        }
      )
    : [];
}
